import { AiOutlineBank } from "react-icons/ai";
import { RxDashboard } from "react-icons/rx";
import { PiStudentBold } from "react-icons/pi";
import { GiTeacher } from "react-icons/gi";

const SideBarRoute = [
  {
    path: "/app/dashboard",
    img: <RxDashboard />,
    name: "Dashboard",
    heading: "",
  },
  {
    path: "/app/student",
    img: <PiStudentBold />,
    name: "Student",
    heading: "",
  },
  {
    path: "/app/teacher",
    img: <GiTeacher />,
    name: "Teacher",
    heading: "",
  },
];

export default SideBarRoute;
